@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("background.png") ;
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow;}
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 60px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}



#button2 {
  padding: 5px 13px;
  max-height: 60px;
  width: 100px;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}

.wrapperwa {
  height: 200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 1000;
  margin-left: 50px;
}

h1 {
  color: yellow;
}

#connectwalletwa {
    font-size: 28px;
    width: 250px;
    height: 120px;
    background-color: yellow;
}

.titleconnectwalletwa {
  grid-column: 3 ;
  grid-row: 1 ;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display:grid;
  width: 450px;
  height: 100px ;
}


.titlewa {
  grid-column: 2 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  height: 130px;
  width: 450px;
}

.onetitleleft {
  grid-column: 1 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  height: 130px;
  width: 400px;
}

.title2wa {
  grid-column: 7 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitlewa {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  margin-top: 50px;
  display:grid;
  width: 300px;
  height: 100px;
}

.othertitlewa {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  margin-top: 50px;
  display:grid;
  width: 300px;
  height: 100px;
}

.onedescriptiontitlewa {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
}

.onedescriptionwa {
  grid-column: 3;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  margin-left: 30px; 
	align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
}

.onetextwa {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
    font-family: monospace; 
 font-size: 24px;
  font-weight: 500;
}

.oneimagewa {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}




.twotitlewa {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  height: 700px;
}

.twotextwa {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.twoimagewa {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
    width: 300px;
  height: 200px;
}

.twodescriptiontitlewa{
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 300px;
  height: 200px;
}



.stats{
  grid-column: 3;
  margin-top: 30px;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items: center;
  display: grid;
  width: 300px;
  height: 140px;
}


.stats2{
  grid-column: 3;
  margin-top: 30px;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items: center;
  display: grid;
  width: 300px;
  height: 140px;
}

.wizards{
  margin-top: 70px;
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}

.relics{
  margin-top: 70px;
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}


.upgrade{
margin-top: 55px;
  grid-column: 3;
  grid-row: 4 ;
  border-radius: 12px;
  text-align: center;
  align-items: center;
  width: 300px;
  height: 400px;
}


#bronze{
  width: 250px;
}

.wyverns{
  margin-top: 70px;
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}


.pixale{
  margin-top: 70px;
  grid-column: 4;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}


.witches{
  grid-column: 2;
  margin-top: 150px;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 70px;
}


.twodescriptionwa {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  font-family: monospace; 
}


.threetitlewa {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
}

.threetextwa {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: right;
  align-items:center;
  display:grid;
  margin: 40px
}

.threeimagewa {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.fourtitlewa {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}