@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #808080;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../static/media/background.cf1b9528.png);
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow; }
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 40px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}

#entry {
  font-size: 20px;
  font-family: monospace;
  border-radius: 8px;
  background-color: rgb(158, 158, 158);
  width: 200px;
}

#entry1{ 
    font-size: 20px;
  font-family: monospace;
    width: 200px;
}

.wrapper {
  height: 1670px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 100;
}



#connectwallet {
    grid-column: 1;
    grid-row: 1;
    font-size: small;
    width: 20px;
    height: 100px;
    background-color: yellow;
}

.titleconnectwallet {
  grid-column: 1 ;
  grid-row: 1 ;
  border-radius: 6px;
  align-items: Center;
  text-align: Center;
  display:grid;
  width: 40px;
  height: 100px ;
  font-size: small;
}

#textt {
  max-width: 500px;
  margin: auto
}


#buttonsize {
  font-size: 20px;
}

.title {
  margin-top: 30px;
  grid-column: 1 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  height: 130px;
  font-size: small;
}


.title2 {
  grid-column: 1 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitle {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;

}

.onedescriptiontitle {
  grid-column: 1;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
      height:450px;
}

.onedescription {
  grid-column: 1;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
      height:450px;
}

.onetext {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  margin-top: 150px;
    margin-right: 60px;
}

.oneimage {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
  height: 400px;
}


#mintButton2 {
width: 300px;

}

.twotitle {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
    height:450px;
}

.twotext {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;  
  margin: 40px;
  margin-right: 60px;
  height: 400px;
  margin-top: 800px;
}

.twoimage {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: left;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 0px 40px;
}

.twodescriptiontitle {
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
    height:450px;
}

.twodescription {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
    height:400px;
}


.threetitle {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
    height:450px;
}

.threetext {
  grid-column: 1;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  margin-top:750px;
    margin-right: 60px;
  height:400px;
}

.threeimage {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: top;
  justify-content:bottom;
  display:grid;
  margin: 0px 60px;
}

.threedescriptiontitle {
  grid-column: 3;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  height:450px;
}

.threedescription {
  grid-column: 3;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
    height:400px;
  font-family: monospace; 
}



.fourtitle {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}
body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../static/media/background.cf1b9528.png);
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow; }
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 60px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}


.wrapperrewards {
  height: 1500px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 1000;
}


.titler {
  grid-column: 2 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;

}

#connectwallet {
    font-size: 28px;
    width: 250px;
    height: 120px;
    background-color: yellow;
}

.titleconnectwallet {
  grid-column: 3 ;
  grid-row: 1 ;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display:grid;
  width: 450px;
  height: 100px ;
}


.title2r {
  grid-column: 4 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitler {
  grid-column: 2;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
}

.onedescriptiontitler {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
}

.onedescriptionr {
  grid-column: 3;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
}

.onetextr {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: left;
  align-items:center;
  display:grid;
  margin: 40px;
    font-family: monospace; 
 font-size: 24px;
  font-weight: 500;
}

.oneimager {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}




.twotitler {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
  height: 700px;
}

.twotextr {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.twoimager {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.twodescriptiontitler{
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
  height: 700px;
}

.twodescriptionr {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  font-family: monospace; 
}


.threetitler {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
}

.threetextr {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: right;
  align-items:center;
  display:grid;
  margin: 40px
}

.threeimager {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.fourtitler {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}
body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../static/media/background.cf1b9528.png);
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow; }
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 60px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}

#mintbutton2 {
  text-align: center;
  align-items: center;
}

.wrapperw {
  height: 1290px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 1300;
}



.titlew {
  grid-column: 2 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;

}

.twotitleimagedao {
  width:400px;
  grid-column: 3 ;
  grid-row: 2 ;
  text-align: Center;
  align-items: Center;
  display: grid;
  grid-column: 3;
}

#entry {
  font-size: 20px;
  font-family: monospace;
  border-radius: 8px;
  background-color: rgb(245, 245, 245);
  width: 100px;
}


#webbutton {
  font-size: 24px;
  font-family: monospace;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
  color: azure;
  align-items: center;
}

#connectwallet {
    font-size: 28px;
    width: 250px;
    height: 80px;
    background-color: yellow;
}

.titleconnectwallet {
  grid-column: 3 ;
  grid-row: 1 ;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display:grid;
  width: 450px;
  height: 100px ;
}


.title2w {
  grid-column: 4 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitlew {
  grid-column: 2;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
  height: 270px;
}

.onedescriptiontitlew {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
}

.onedescriptionw {
  grid-column: 3;
  grid-row: 2 ;
  border-radius: 45px;
  text-align:center;
  align-items:Center;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  margin-left: 25px;
  font-size: 30px;
  font-weight: 500;
  font-family: monospace; 
}

.onetextw {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
    font-family: monospace; 
 font-size: 24px;
  font-weight: 500;
}

.oneimagew {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}




.twotitlew {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
  height: 400px;
}

.twotextw {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  margin-top: 75px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 350px;
}


.twoimagew {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.twodescriptiontitlew{
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
  height: 400px;
}


.twodescriptionw {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 400px;
  margin-top: 20px;
  font-family: monospace; 
}


.threetitlew {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
  height: 400px;
}

.threetextw {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 350px;
}


.threeimagew {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.threedescriptiontitlew{
  grid-column: 3;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
  height: 400px;
}

.threedescriptionw {
  grid-column: 3;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 400px;
  margin-top: 20px;
  font-family: monospace; 
}


.fourtitlew {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}
body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../static/media/background.cf1b9528.png);
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow;}
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 60px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}



#button2 {
  padding: 5px 13px;
  max-height: 60px;
  width: 100px;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}

.wrapperwa {
  height: 200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 1000;
  margin-left: 50px;
}

h1 {
  color: yellow;
}

#connectwalletwa {
    font-size: 28px;
    width: 250px;
    height: 120px;
    background-color: yellow;
}

.titleconnectwalletwa {
  grid-column: 3 ;
  grid-row: 1 ;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display:grid;
  width: 450px;
  height: 100px ;
}


.titlewa {
  grid-column: 2 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  height: 130px;
  width: 450px;
}

.onetitleleft {
  grid-column: 1 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  height: 130px;
  width: 400px;
}

.title2wa {
  grid-column: 7 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitlewa {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  margin-top: 50px;
  display:grid;
  width: 300px;
  height: 100px;
}

.othertitlewa {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  margin-top: 50px;
  display:grid;
  width: 300px;
  height: 100px;
}

.onedescriptiontitlewa {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
}

.onedescriptionwa {
  grid-column: 3;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  margin-left: 30px; 
	align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
}

.onetextwa {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
    font-family: monospace; 
 font-size: 24px;
  font-weight: 500;
}

.oneimagewa {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}




.twotitlewa {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  height: 700px;
}

.twotextwa {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.twoimagewa {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
    width: 300px;
  height: 200px;
}

.twodescriptiontitlewa{
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 300px;
  height: 200px;
}



.stats{
  grid-column: 3;
  margin-top: 30px;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items: center;
  display: grid;
  width: 300px;
  height: 140px;
}


.stats2{
  grid-column: 3;
  margin-top: 30px;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items: center;
  display: grid;
  width: 300px;
  height: 140px;
}

.wizards{
  margin-top: 70px;
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}

.relics{
  margin-top: 70px;
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}


.upgrade{
margin-top: 55px;
  grid-column: 3;
  grid-row: 4 ;
  border-radius: 12px;
  text-align: center;
  align-items: center;
  width: 300px;
  height: 400px;
}


#bronze{
  width: 250px;
}

.wyverns{
  margin-top: 70px;
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}


.pixale{
  margin-top: 70px;
  grid-column: 4;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 200px;
}


.witches{
  grid-column: 2;
  margin-top: 150px;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: center;
  width: 300px;
  height: 70px;
}


.twodescriptionwa {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  font-family: monospace; 
}


.threetitlewa {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
}

.threetextwa {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: right;
  align-items:center;
  display:grid;
  margin: 40px
}

.threeimagewa {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.fourtitlewa {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}
