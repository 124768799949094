@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("background.png") ;
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow; }
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 60px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}


.wrapperrewards {
  height: 1500px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 1000;
}


.titler {
  grid-column: 2 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;

}

#connectwallet {
    font-size: 28px;
    width: 250px;
    height: 120px;
    background-color: yellow;
}

.titleconnectwallet {
  grid-column: 3 ;
  grid-row: 1 ;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display:grid;
  width: 450px;
  height: 100px ;
}


.title2r {
  grid-column: 4 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitler {
  grid-column: 2;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
}

.onedescriptiontitler {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
}

.onedescriptionr {
  grid-column: 3;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
}

.onetextr {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: left;
  align-items:center;
  display:grid;
  margin: 40px;
    font-family: monospace; 
 font-size: 24px;
  font-weight: 500;
}

.oneimager {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}




.twotitler {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
  height: 700px;
}

.twotextr {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.twoimager {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.twodescriptiontitler{
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
  height: 700px;
}

.twodescriptionr {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  font-family: monospace; 
}


.threetitler {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
}

.threetextr {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: right;
  align-items:center;
  display:grid;
  margin: 40px
}

.threeimager {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.fourtitler {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 600px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}