@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("background.png") ;
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow; }
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 40px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}

#entry {
  font-size: 20px;
  font-family: monospace;
  border-radius: 8px;
  background-color: rgb(158, 158, 158);
  width: 200px;
}

#entry1{ 
    font-size: 20px;
  font-family: monospace;
    width: 200px;
}

.wrapper {
  height: 1670px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 100;
}



#connectwallet {
    grid-column: 1;
    grid-row: 1;
    font-size: small;
    width: 20px;
    height: 100px;
    background-color: yellow;
}

.titleconnectwallet {
  grid-column: 1 ;
  grid-row: 1 ;
  border-radius: 6px;
  align-items: Center;
  text-align: Center;
  display:grid;
  width: 40px;
  height: 100px ;
  font-size: small;
}

#textt {
  max-width: 500px;
  margin: auto
}


#buttonsize {
  font-size: 20px;
}

.title {
  margin-top: 30px;
  grid-column: 1 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  height: 130px;
  font-size: small;
}


.title2 {
  grid-column: 1 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitle {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;

}

.onedescriptiontitle {
  grid-column: 1;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
      height:450px;
}

.onedescription {
  grid-column: 1;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
      height:450px;
}

.onetext {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  margin-top: 150px;
    margin-right: 60px;
}

.oneimage {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
  height: 400px;
}


#mintButton2 {
width: 300px;

}

.twotitle {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
    height:450px;
}

.twotext {
  grid-column: 1;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;  
  margin: 40px;
  margin-right: 60px;
  height: 400px;
  margin-top: 800px;
}

.twoimage {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: left;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 0px 40px;
}

.twodescriptiontitle {
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
    height:450px;
}

.twodescription {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  font-family: monospace; 
    height:400px;
}


.threetitle {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
    height:450px;
}

.threetext {
  grid-column: 1;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  margin-top:750px;
    margin-right: 60px;
  height:400px;
}

.threeimage {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: top;
  justify-content:bottom;
  display:grid;
  margin: 0px 60px;
}

.threedescriptiontitle {
  grid-column: 3;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  height:450px;
}

.threedescription {
  grid-column: 3;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 350px;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
    height:400px;
  font-family: monospace; 
}



.fourtitle {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 650px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}