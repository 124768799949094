@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 12;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("background.png") ;
  background-color: black;
  color: rgb(255, 255, 255);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#entry{
  align-items: right;
  max-width: 200px;
  box-sizing: border-box;
}

text {
  margin: 0;
  padding: 0;
}

h2 { font-weight:normal;
      color: yellow; }
      
h5 { font-weight:normal;}
h3 { font-weight:normal;}

button {
  padding: 5px 13px;
  max-height: 60px;
  width: 40%;
  background-position-x: right;
  border-color: #000000;
  color: #000000;
  background-color: rgb(245, 245, 245,0.5);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  cursor:pointer;
  display:inline-block;
  font-family: monospace;
}

#mintbutton2 {
  text-align: center;
  align-items: center;
}

.wrapperw {
  height: 1290px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  grid-row-start: 1300;
}



.titlew {
  grid-column: 2 ;
  grid-row: 1 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;

}

.twotitleimagedao {
  width:400px;
  grid-column: 3 ;
  grid-row: 2 ;
  text-align: Center;
  align-items: Center;
  display: grid;
  grid-column: 3;
}

#entry {
  font-size: 20px;
  font-family: monospace;
  border-radius: 8px;
  background-color: rgb(245, 245, 245);
  width: 100px;
}


#webbutton {
  font-size: 24px;
  font-family: monospace;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
  color: azure;
  align-items: center;
}

#connectwallet {
    font-size: 28px;
    width: 250px;
    height: 80px;
    background-color: yellow;
}

.titleconnectwallet {
  grid-column: 3 ;
  grid-row: 1 ;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display:grid;
  width: 450px;
  height: 100px ;
}


.title2w {
  grid-column: 4 ;
  grid-row: 1;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
}


.onetitlew {
  grid-column: 2;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
  height: 270px;
}

.onedescriptiontitlew {
  grid-column: 3;
  grid-row: 2 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
}

.onedescriptionw {
  grid-column: 3;
  grid-row: 2 ;
  border-radius: 45px;
  text-align:center;
  align-items:Center;
  justify-content:left + 20px;
  display:grid;
  width: 400px;
  margin-left: 25px;
  font-size: 30px;
  font-weight: 500;
  font-family: monospace; 
}

.onetextw {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
    font-family: monospace; 
 font-size: 24px;
  font-weight: 500;
}

.oneimagew {
  grid-column: 2;
  grid-row: 2 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}




.twotitlew {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
  height: 400px;
}

.twotextw {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  margin-top: 75px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 350px;
}


.twoimagew {
  grid-column: 2;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.twodescriptiontitlew{
  grid-column: 3;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
  height: 400px;
}


.twodescriptionw {
  grid-column: 3;
  grid-row: 3 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 400px;
  margin-top: 20px;
  font-family: monospace; 
}


.threetitlew {
  grid-column: 2;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
  height: 400px;
}

.threetextw {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: center;
  align-items:center;
  display:grid;
  margin: 40px;
  font-family: monospace;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 350px;
}


.threeimagew {
  grid-column: 2;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  justify-content:left;
  display:grid;
  margin: 10px 60px;
}

.threedescriptiontitlew{
  grid-column: 3;
  grid-row: 4 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 450px;
  height: 400px;
}

.threedescriptionw {
  grid-column: 3;
  grid-row: 4 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:Center;
  display:grid;
  margin-left: 25px;
  font-size: 24px;
  font-weight: 500;
  width: 400px;
  height: 400px;
  margin-top: 20px;
  font-family: monospace; 
}


.fourtitlew {
  grid-column: 2;
  grid-row: 5 ;
  border-radius: 45px;
  text-align: Center;
  align-items: Top;
  justify-content:left + 20px;
  display:grid;
  width: 500px;
}



h4{
    margin:0;
    padding:0;
}

.two {
  grid-column: 2;
  grid-row: 3 ;
  background-color: rgb(146, 146, 189, 0.3);
  border-radius: 45px;
  text-align: Center;
  align-items: Center;
  display:grid;
}
.three {
  grid-column: 1;
  grid-row: 2 / 5;
}
.four {
  grid-column: 3;
  grid-row: 3;
}
.five {
  grid-column: 2;
  grid-row: 4;
}
.six {
  grid-column: 3;
  grid-row: 4;
}